
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import CabinetMenu from '../CabinetParts/CabinetMenu'
import Search from './Search'
import MainPhones from '@/components/contactsParts/MainPhones'
import PhoneButton from '@/components/buttons/phone'
import DialogCompare from '@/components/core/dialogCompare'

export default {
  name: 'MobileAppBar',
  components: {
    Search,
    MainPhones,
    DialogCompare,
    PhoneButton,
    CabinetMenu,
  },
  notifications: {
    showToast: {},
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    story: {},
    active_menu: [],
    language: 'uk',
    flag: process.env.STORAGE_GOOGLEAPIS + '/flags/ua.png',
  }),
  async fetch() {
    const res = await this.$storyapi.get('cdn/stories', {
      starts_with: 'pages/contacts',
    })
    // Let's convert content.date from a String to a Date
    const page = res.data.stories.map((story) => {
      story.content.date = new Date(story.content.date)
      return story
    })
    this.story = page[0]
    return { page }
  },
  computed: {
    ...mapState(['drawer', 'auth', 'notifications', 'cart', 'searchBar']),
    ...mapGetters({
      cart_count: 'cart/getCartPositionCount',
      Categories: 'compare/listCategory',
    }),
    showPhonesDialog: {
      get() {
        return this.$store.getters['buttons/dialogPhones']
      },
      set() {
        this.$store.dispatch('buttons/toggleDialogPhones')
      },
    },
    sumAllProducts() {
      let sum = 0
      this.Categories.forEach((x) => {
        sum += x?.items.length
      })

      return sum
    },
  },
  watch: {
    language() {
      if (this.$i18n.locale === 'uk') {
        this.flag = process.env.STORAGE_GOOGLEAPIS + '/flags/ua.png'
      } else if (this.$i18n.locale === 'ru') {
        this.flag = process.env.STORAGE_GOOGLEAPIS + '/flags/ru.png'
      } else if (this.$i18n.locale === 'en') {
        this.flag = process.env.STORAGE_GOOGLEAPIS + '/flags/gb.png'
      }
    },
    $route() {
      this.active_menu = []
    },
  },
  methods: {
    ...mapActions('compare', ['toggleDialogCompare']),
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
      TOOGLECART: 'TOOGLE_CART',
      toggleSearch: 'TOOGLESEARCH',
    }),
    async logout() {
      await this.$auth.logout().then(() =>
        this.showToast({
          type: 'info',
          title: this.$t('logout'),
          message: this.$t('logged_out'),
          timeout: 3000,
          iconUrl: this.$vuetify.icons.values.arrowLeft,
        })
      )
    },
  },
}
